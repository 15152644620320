<template>
  <div class="main-lottery">
    <div class="col-xl-4 col-lg-5 col-md-6 col-12  mx-auto lottery-card px-0">
      <b-card class="border-0 border-radius10 text-center pt-3 mx-2">
        <router-link to="/" >
          <img
            src="../../assets/logo.png"
            style="height: 55px;"
            class="header-brand-img desktop-logo "
          />
        </router-link>
        <h5 class="weight-bold text-color-555 mt-3">شرکت در قرعه‌کشی آلبا </h5>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            @submit.prevent="(e) => handleSubmit(() => submitLottery(e))"
            class=" pt-3 "
          >
            <div class=" d-flex flex-column">
              <ValidationProvider
                mode="passive"
                class="mb-3 text-right"
                rules="required"
                v-slot="v"
              >
                <input
                  type="text"
                  v-model="fullName"
                  name="name"
                  class="shadow-input p-2 text-right w-100 mb-1"
                  placeholder="نام و نام خانوادگی"
                />
                <span class="text-right text-danger mt-2">{{
                  v.errors[0]
                }}</span>
              </ValidationProvider>
              <ValidationProvider
                  mode="passive"
                  class="mb-3 text-right"
                  rules="required"
                  v-slot="v"
              >
                <input
                    type="text"
                    v-model="instagram"
                    name="instagram"
                    class="shadow-input p-2 text-right w-100 mb-1"
                    placeholder="آیدی اینستاگرام"
                />
                <span class="text-right text-danger mt-2">{{
                    v.errors[0]
                  }}</span>
              </ValidationProvider>
              <ValidationProvider
                mode="passive"
                class="mb-3 text-right"
                rules="required|phone_number"
                v-slot="v"
              >
                <input
                  v-number
                  type="text"
                  v-model="phoneNumber"
                  name="phone_number"
                  maxlength="11"
                  class="shadow-input p-2 text-right w-100 mb-1"
                  placeholder="شماره موبایل"
                />
                <span class="text-right text-danger mt-2">{{
                  v.errors[0]
                }}</span>
              </ValidationProvider>
              <ValidationProvider name="province" mode="passive"
                    rules="required" v-slot="v">
                    <multiselect :disabled="sendingRequest" v-if="provinces" class="text-right" v-model="selected_province" :options="provinces"
                        track-by="id" label="name" :searchable="true" :close-on-select="true" :show-labels="false"
                        placeholder="استان">
                    </multiselect>
                     <span class="d-block text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                    <input name="province_id" @change="v.validate" type="hidden"
                                :value="selected_province != null ? selected_province.id : ''">

                </ValidationProvider>
              <ValidationProvider name="province" mode="passive"
                                  rules="required" v-slot="v">
                <multiselect :disabled="sendingRequest" class="text-right mt-2" v-model="selected_gender" :options="genders"
                             track-by="id" label="name" :searchable="true" :close-on-select="true" :show-labels="false"
                             placeholder="جنسیت">
                </multiselect>
                <span class="d-block text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
                <input name="gender" @change="v.validate" type="hidden"
                       :value="selected_gender.value">

              </ValidationProvider>
            </div>

            <input
              :disabled="sendingRequest"
              type="submit"
              class="py-2 mt-3 btn-lottery weight-bold px-4 text-color-themeBlue"
              value="ثبت نام"
            />
          </form>
        </ValidationObserver>
      </b-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      url: "/api/lottery",
      phoneNumber: "",
      fullName: "",
      sendingRequest: false,
      provinces: null,
      genders: [{
        name: 'آقا',
        value: 'male',
      }, {
        name: 'خانم',
        value: 'female',
      }],
      selected_province: null,
      selected_gender: '',
      instagram: ""
    };
  },
  mounted() {
      this.loadProvinces()
  },
  methods: {
      loadProvinces() {
     this.$axios.get('/api/provinces/' + 102)
            .then(response => {
              this.provinces = response.data.data
            })
    },
    submitLottery(e) {
      this.sendingRequest = true;
      let formData = new FormData(e.target);
      this.$axios
        .post(this.url, formData)
        .then((response) => {
          window.swal(response.data.message, { button: "باشه" });
          this.fullName = "";
          this.phoneNumber = "";
          this.selected_province = null
          this.selected_gender = '';
          this.instagram = "";
        })
        .catch((error) => {
          this.$root.error_notification(error);
        })
        .finally(() => {
          this.sendingRequest = false;
        });
    },
  },
};
</script>
<style scoped>
.main-lottery {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-themeBlue);
  overflow: hidden;
}
.lottery-card {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.lottery-card .card {
  box-shadow: 0 0 20px #c9c5f9;
}
.lottery-card input {
  border: 1px solid #7570b399;
  border-radius: 10px;
}
.main-lottery .btn-submit {
  background-color: var(--color-themeBlue-i);
}
.btn-lottery {
  transition: all 0.3s ;
}
.btn-lottery:hover{
  box-shadow: 0 0 20px #c9c5f9;
  background-color: var(--color-themeBlue);
  color: white !important;

}
@media screen and (max-width: 600px) {
    .lottery-card {
  top: 30%;
  transform: translateY(-30%);
}
}
</style>
<style >
    .main-lottery  .multiselect__tags {
        border: 1px solid #7570b399;
        border-radius: 10px;
    }
    .main-lottery .multiselect__placeholder {
        color: #777;
    }
     .main-lottery .multiselect,
     .main-lottery .multiselect__input,
     .main-lottery .multiselect__single,
     .main-lottery .multiselect__tags {
        font-size: 12px !important;
    }

     .main-lottery .multiselect__option--highlight::after,
     .main-lottery .multiselect__option--highlight,
     .main-lottery .multiselect__tag {
        background: var(--color-themeBlue);
    }

     .main-lottery .multiselect__option--selected.multiselect__option--highlight::after,
     .main-lottery .multiselect__option--selected.multiselect__option--highlight,
     .main-lottery .multiselect__tag-icon:hover {
        background: var(--color-themeBlue-i) !important;
    }
</style>
